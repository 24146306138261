import { Button, MenuItemType, Select, ToastType } from '@components';
import { useNavigate, useParams, useSearchParams } from '@solidjs/router';
import { openModal } from '@store/modals';
import {
  copyOrder,
  fetchOrderTemplateData,
  orderStore,
  setOrderStore,
  TrySaveOrder,
  updateOrderFormErrorState,
} from '@store/orders';
import { userStore } from '@store/user';
import { ControlPointDuplicate } from '@suid/icons-material';
import FeedIcon from '@suid/icons-material/Feed';
import SaveIcon from '@suid/icons-material/Save';
import { Skeleton } from '@suid/material';
import { routeOrderDetailsCopyOrder } from '@utils/routeOrderDetails';
import { handleToast, printError } from '@utils/utils';
import { validateOrder } from '@utils/validationUtils';
import { createEffect, createSignal, Show } from 'solid-js';

import MoreOptions from './MoreOptions';
import { TemplateListModal } from './TemplateListModal';

export function HeaderControl() {
  const params = useParams();
  const [query] = useSearchParams();
  const isCreate = !params.id;

  const [selectedTemplateId, setSelectedTemplateId] =
    createSignal<string>('Select a Template');
  const [orderTemplates, setOrderTemplates] = createSignal<MenuItemType[]>([]);

  const navigate = useNavigate();
  const handleTemplateSelect = async (templateId: string) => {
    setSelectedTemplateId(templateId);
    setOrderStore('isCreate', true);
    await fetchOrderTemplateData(Number(templateId), true);
    setSelectedTemplateId('Select a Template');
  };
  createEffect(() => {
    if (orderTemplates().length === 0 && orderStore.orderTemplates.length > 0) {
      const templates = orderStore.orderTemplates.map((template) => ({
        label: template.name,
        value: String(template.id),
      }));
      setOrderTemplates(templates);
    }
  });
  createEffect(() => {
    if (orderStore.isSaving) {
      setSelectedTemplateId('SELECT A TEMPLATE');
    }
  });
  createEffect(() => {
    if (isCreate) {
      setOrderStore('isCreate', true);
    }
  });

  return (
    <Show
      when={!orderStore.loading}
      fallback={
        <div class="flex justify-start mr-2 pl-4">
          <Skeleton variant="rectangular" height={20} width={200} />
        </div>
      }
    >
      <div class="flex space-x-2 justify-start pl-6">
        {!orderStore.isTemplateMode ? (
          <Button
            startIcon={<SaveIcon />}
            variant="contained"
            size="small"
            label="SAVE"
            // why are we calling validate just to save the order as well
            onClick={async () => {
              try {
                if (validateOrder() ?? false) {
                  await TrySaveOrder(navigate, query.quoteId);
                }
              } catch (error) {
                printError(error);
                handleToast(ToastType.Error, 'Failed to save order');
              }
            }}
            type="submit"
            isLoading={orderStore.isSaving}
            disabled={
              orderStore.isSaving ||
              (orderStore.order.id != 0 && orderStore.isReadOnly) ||
              (orderStore.order.id != 0 &&
                Boolean(orderStore.orderLockEnabled) &&
                (orderStore.lockedBy?.id ?? -1) !== userStore.user.id)
            }
            sx={{ width: '92px', marginRight: '16px' }}
          />
        ) : null}
        <div class="hidden 1080p:block">
          <Button
            startIcon={<ControlPointDuplicate />}
            label="Copy Order"
            sx={{ height: '100%' }}
            disabled={orderStore.order.id === 0}
            size="small"
            variant="outlined"
            class="whitespace-nowrap"
            onClick={async () => {
              setOrderStore('isCreate', true);
              const newOrder = await copyOrder();
              setOrderStore('order', newOrder);
              if (orderStore.copiedOrderId !== undefined) {
                const isNative = routeOrderDetailsCopyOrder(
                  orderStore.copiedOrderId,
                );
                if (isNative) {
                  navigate(
                    `/order/details/?copyOrderId=${orderStore.copiedOrderId}`,
                  );
                }
              }
              updateOrderFormErrorState({});
            }}
          />
        </div>
        <MoreOptions />
        <Select
          value={selectedTemplateId()}
          onChange={async (e) => {
            await handleTemplateSelect(e.target.value);
          }}
          menuItems={orderTemplates()}
          size="small"
          label=""
          sxProps={{ color: '#666666' }}
          width={200}
        />
        <Button
          startIcon={<FeedIcon />}
          label="Templates"
          size="small"
          onClick={() => openModal('orderTemplateModal')}
          class="!py-1 !px-2 w-[40%] !ml-2"
          color="secondary"
          sx={{ width: '121px' }}
        />
      </div>
      <TemplateListModal />
    </Show>
  );
}
