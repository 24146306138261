import { ToastType } from '@components';
import mapConfig from '@components/Map/mapConfig';
import { ConfigManager } from '@utils/ConfigManager';
import {
  deepConvertCamelToPascalCase,
  deepConvertPascalToCamelCase,
} from '@utils/caseTransformers';
import { logError } from '@utils/errorLogging';
import { routeLoginPage } from '@utils/routeLoginPage';
import { handleToast, printLog } from '@utils/utils';
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

import { ApiErrorT, ApiResponseT, ErrorResponseT, ErrorT } from './types';

export type ApiResponse<T> = {
  error?: {
    code: string;
    message: string;
  };
  errors?: {
    code: string;
    message: string;
  }[];
  isFailure: boolean;
  isSuccess: boolean;
  value: T;
};

const TIMEOUT_DURATION = 30_000;

const apiClient: AxiosInstance = axios.create({
  baseURL: `${ConfigManager.apiUrl}/api/`, // Set your API base URL
  timeout: TIMEOUT_DURATION,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Enable sending cookies in the request
});

const v3Client: AxiosInstance = axios.create({
  baseURL: `${ConfigManager.yarpHost}/api/`, // Set your API base URL
  timeout: TIMEOUT_DURATION,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Enable sending cookies in the request
});

const v3ODataClient: AxiosInstance = axios.create({
  baseURL: `${ConfigManager.yarpHost}/odata/`, // Set your API base URL
  timeout: TIMEOUT_DURATION,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Enable sending cookies in the request
});

const pcMilerClient: AxiosInstance = axios.create({
  baseURL: mapConfig.PCMiler.PCMilerBaseUrl,
  timeout: TIMEOUT_DURATION,
  headers: {
    'Content-Type': 'application/json',
    Authorization: mapConfig.PCMiler.PCMilerAPIKey,
  },
});

const mapClient: AxiosInstance = axios.create({
  baseURL: mapConfig.PCMiler.PCMilerBaseUrl,
  timeout: TIMEOUT_DURATION,
  headers: {
    'Content-Type': 'application/json',
    Authorization: mapConfig.PCMiler.PCMilerAPIKey,
  },
  withCredentials: false,
});

const v3FileUploadClient: AxiosInstance = axios.create({
  baseURL: `${ConfigManager.yarpHost}/api/`,
  timeout: TIMEOUT_DURATION,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  withCredentials: true,
});

const apiFileUploadClient: AxiosInstance = axios.create({
  baseURL: `${ConfigManager.apiUrl}/api/`,
  timeout: 30000,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  withCredentials: true,
});

const falveyClient: AxiosInstance = axios.create({
  baseURL: `${ConfigManager.falveyUrl}api/`, // Set your API base URL
  timeout: TIMEOUT_DURATION,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Enable sending cookies in the request
});

const isAxiosDebugActive = ConfigManager.isDebugging === 'true';

export function authorizationInterceptor(config: InternalAxiosRequestConfig) {
  if (isAxiosDebugActive) {
    printLog('Request:', config);
  }
  return config;
}

// Request Interceptor
apiClient.interceptors.request.use(
  authorizationInterceptor,
  (error: AxiosError) => {
    printLog('axios error', error);
    if (isAxiosDebugActive) {
      printLog(error);
    }
    return Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse<ApiResponse<unknown>> => {
    if (isAxiosDebugActive) {
      // eslint-disable-next-line no-console
      console.log('Response:', response);
    }
    // eslint-disable-next-line no-console
    console.log('Response:', response);
    const { status } = response;

    if (status >= 200 && status < 300) {
      return response.data as AxiosResponse<ApiResponse<unknown>>;
    } else if (status >= 300 && status < 400) {
      // eslint-disable-next-line no-console
      console.error('Redirection Error:', response.data);
    } else if (status === 401) {
      const err = new Error('Session timeout');
      logError('sessionTimeOut', err);
      routeLoginPage();
    } else if (status >= 400 && status < 500) {
      // eslint-disable-next-line no-console
      console.error('Client Error:', response.data);
    } else if (status >= 500) {
      handleToast(
        ToastType.Error,
        'There was a cool error here that is a server error',
      );
      // eslint-disable-next-line no-console
      console.error('Server Error:', response.data);
    }

    return Promise.reject(response);
  },
  (error: AxiosError) => {
    if (error.response?.statusText === 'Unauthorized') {
      const err = new Error('Session timeout');
      logError('sessionTimeOut', err);
      routeLoginPage();
      return Promise.reject([{ errorMessage: 'You have been logged out' }]);
    }

    const errorResponse = error as unknown as {
      response: ErrorResponseT;
    };

    if (Boolean(errorResponse.response.data.errors)) {
      const errors = errorResponse.response.data.errors;
      let errorMessages: ErrorT[] = [];

      if ('map' in errors) {
        errorMessages = errors as ErrorT[];
      } else {
        // If the errors field is an object, its keys will represent the section of the POST/PUT
        // data that failed) with an array of string messages. So we create an error object for
        // each of these messages and reduce the bigger object into an array of errors.
        errorMessages = Object.keys(errors).reduce((acc, key) => {
          errors[key].forEach((err) => {
            acc.push({
              field: key,
              errorMessage: err,
            });
          });
          return acc;
        }, [] as ErrorT[]);
      }

      return Promise.reject(errorMessages);
    }

    const tempError = error as unknown as
      | ApiResponseT<ApiErrorT[]>
      | ApiResponseT<ApiErrorT>;
    let errorMessages: string[] = [];
    if (tempError.response.data instanceof Array) {
      errorMessages = tempError.response.data.map(
        // I'm being overly cautious here
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (errorObj) => errorObj.message ?? 'Unknown error',
      );
    } else if (typeof error.response?.status !== 'string') {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (Boolean(tempError.response.data.detail)) {
        errorMessages.push(tempError.response.data.detail);
      } else {
        errorMessages.push(
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          tempError.response.data.message ?? 'Unknown error',
        );
      }
    }

    return Promise.reject(errorMessages);
  },
);

v3Client.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse<unknown> => {
    if (response.status >= 200 && response.status < 300) {
      return deepConvertPascalToCamelCase(
        response.data,
      ) as AxiosResponse<unknown>;
    }
    if (response.status === 401) {
      routeLoginPage();
    }
    return response as AxiosResponse<unknown>;
  },
  (error) => {
    if (error instanceof Error) {
      const errorResponse = error as unknown as {
        response: { data: { Message: string; Messages: string[] } };
      };
      handleToast(
        ToastType.Error,
        errorResponse.response.data.Message ||
          errorResponse.response.data.Messages.join(' , ') ||
          'Something went wrong',
      );
      return Promise.reject(error);
    }
  },
);

v3Client.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  config = {
    ...config,
    data: deepConvertCamelToPascalCase(config.data),
  };
  return config;
}),
  (error: AxiosError) => {
    logError('v3ClientRequestError', error);

    if (isAxiosDebugActive) {
      printLog(error);
    }

    return Promise.reject(error);
  };

pcMilerClient.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse<unknown> => {
    if (response.status >= 200 && response.status < 300) {
      return deepConvertPascalToCamelCase(
        response.data,
      ) as AxiosResponse<unknown>;
    }
    return response as AxiosResponse<unknown>;
  },
  (error) => {
    // Do something with response error
    return Promise.reject(error);
  },
);

pcMilerClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  config = {
    ...config,
    data: deepConvertCamelToPascalCase(config.data),
  };
  return config;
}),
  (error: AxiosError) => {
    logError('pcMilerClientRequestError', error);

    if (isAxiosDebugActive) {
      printLog(error);
    }

    return Promise.reject(error);
  };

v3ODataClient.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse<unknown> => {
    if (response.status >= 200 && response.status < 300) {
      return deepConvertPascalToCamelCase(
        response.data,
      ) as AxiosResponse<unknown>;
    }
    if (response.status === 401) {
      routeLoginPage();
    }
    return response as AxiosResponse<unknown>;
  },
  (error) => {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        (
          error as unknown as {
            response: { data: { error: { message: string } } };
          }
        ).response.data.error.message || 'Something went wrong',
      );
      return Promise.reject(error);
    }
  },
);

v3ODataClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  config = {
    ...config,
    data: deepConvertCamelToPascalCase(config.data),
  };
  return config;
}),
  (error: AxiosError) => {
    logError('v3ODataClientRequestError', error);

    if (isAxiosDebugActive) {
      printLog(error);
    }

    return Promise.reject(error);
  };

v3FileUploadClient.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse<unknown> => {
    if (response.status >= 200 && response.status < 300) {
      return deepConvertPascalToCamelCase(
        response.data,
      ) as AxiosResponse<unknown>;
    }
    if (response.status === 401) {
      routeLoginPage();
    }
    return response as AxiosResponse<unknown>;
  },
  (error) => {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        (error as unknown as { response: { data: { Message: string } } })
          .response.data.Message,
      );
      return Promise.reject(error);
    }
  },
);

v3FileUploadClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    return config;
  },
  (error: AxiosError) => {
    logError('v3FileUploadClientRequestError', error);

    if (isAxiosDebugActive) {
      printLog(error);
    }

    return Promise.reject(error);
  },
);

falveyClient.interceptors.request.use(
  authorizationInterceptor,
  (error: AxiosError) => {
    logError('falveyClientRequestError', error);

    if (isAxiosDebugActive) {
      printLog(error);
    }

    return Promise.reject(error);
  },
);

falveyClient.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse<ApiResponse<unknown>> => {
    if (isAxiosDebugActive) {
      // eslint-disable-next-line no-console
      console.log('Response:', response);
    }
    const { status } = response;

    if (status >= 200 && status < 300) {
      return response.data as AxiosResponse<ApiResponse<unknown>>;
    } else if (status >= 300 && status < 400) {
      // eslint-disable-next-line no-console
      console.error('Redirection Error:', response.data);
    } else if (status === 401) {
      routeLoginPage();
    } else if (status >= 400 && status < 500) {
      // eslint-disable-next-line no-console
      console.error('Client Error:', response.data);
    } else if (status >= 500) {
      // eslint-disable-next-line no-console
      console.error('Server Error:', response.data);
    }

    return Promise.reject(response);
  },
  (error: AxiosError) => {
    if (error instanceof Error) {
      if (error.response?.statusText === 'Unauthorized') {
        routeLoginPage();
      }
      const errorResponse = error as unknown as {
        response: { data: { errors: [{ errorMessage: string }] } };
      };

      if (Boolean(errorResponse.response.data.errors)) {
        const errorMessage = errorResponse.response.data.errors
          .map((errorObj) => errorObj.errorMessage)
          .join(' , ');
        handleToast(ToastType.Error, errorMessage);
        return Promise.reject(error);
      }

      const tempError = error as unknown as
        | ApiResponseT<ApiErrorT[]>
        | ApiResponseT<ApiErrorT>;
      let errorMessages: string[] = [];

      if (tempError.response.data instanceof Array) {
        errorMessages = tempError.response.data.map(
          // I'm being overly cautious here
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          (errorObj) => errorObj.message ?? 'Unknown error',
        );
      } else if (typeof error.response?.status !== 'string') {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        errorMessages.push(tempError.response.data.message ?? 'Unknown error');
      }

      handleToast(ToastType.Error, errorMessages.join(' , '));

      return Promise.reject(error);
    }
  },
);

export {
  apiClient,
  apiFileUploadClient,
  falveyClient,
  mapClient,
  pcMilerClient,
  v3Client,
  v3FileUploadClient,
  v3ODataClient,
};
