import {
  Button,
  Card,
  DatePicker,
  MenuItemType,
  Notification,
  TimePicker,
  ToastType,
} from '@components';
import { SelectField, TextInput } from '@components/forms';
import { createForm } from '@felte/solid';
import {
  EDIStatusCodesApiResponse,
  ILoadEditViewModel,
  LastUsedTopStopModel,
  fetchEDIStatusCodes,
  orderStore,
  sendEDIUpdate,
} from '@store/orders';
import { Box, Grid, Stack } from '@suid/material';
import { getEligibleEntries, handleToast } from '@utils/utils';
import { find, groupBy, isEmpty, size } from 'lodash';
import { DateTime } from 'luxon';
import { Component, Show, createSignal, onMount } from 'solid-js';

import { mapCodesToMenuItems } from './helper';

export type Props = {
  load: ILoadEditViewModel;
  index: number;
};

type FormValues = {
  appointmentStatus: string | null;
  date: Date;
  note: string;
  shipmentStatus: string;
  statusReason: string;
  stopId: number;
  timezone: string;
  time: string;
  appointmentNumber: string;
  city: string | null;
  state: string | null;
  latitude: string | null;
  longitude: string | null;
  temperature: string | null;
};

const SendEDIUpdate: Component<Props> = (props: Props) => {
  const [statusCodes, setStatusCodes] = createSignal<EDIStatusCodesApiResponse>(
    {
      statusCodes: [],
      statusReasons: [],
      appointmentCodes: [],
    },
  );

  const getStopLocation = () => {
    const eligibleStops = getEligibleEntries<LastUsedTopStopModel>(
      props.load.stops,
    );
    return size(eligibleStops) > 0
      ? eligibleStops
          .filter((stop) => stop.id)
          .map((stop) => ({
            label: stop.locationName,
            value: Number(stop.id),
          }))
      : [];
  };

  const { form, data, setFields, reset } = createForm<FormValues>({
    initialValues: {
      appointmentStatus: null,
      date: DateTime.now().toJSDate(),
      note: '',
      shipmentStatus: '',
      statusReason: 'NS',
      stopId: getStopLocation()[0]?.value ?? 0,
      timezone: 'EST',
      time: DateTime.now().toISO(),
      appointmentNumber: '',
    },
    onSubmit: async function (values: FormValues) {
      const {
        appointmentStatus,
        appointmentNumber,
        note,
        shipmentStatus,
        statusReason,
        stopId,
        timezone,
        date,
        time,
        latitude,
        longitude,
        city,
        state,
        temperature,
      } = values;

      if (shipmentStatus === '') {
        handleToast(ToastType.Error, '"No Update Event has been selected"');
        return;
      }

      if (
        x6Selected() &&
        !(
          (!isEmpty(data().city) && !isEmpty(data().state)) ||
          (!isEmpty(data().latitude) && !isEmpty(data().longitude))
        )
      ) {
        handleToast(
          ToastType.Error,
          'To send an (X6) En Route update, you need to enter either a City and State, or, Latitude and Longitude.',
        );
        return;
      }

      const payload = {
        appointmentStatus,
        appointmentNumber,
        note,
        shipmentStatus,
        statusReason,
        stopId,
        timezone,
        date: `${DateTime.fromJSDate(date).toFormat(
          'MM/dd/yyyy',
        )} ${DateTime.fromISO(time).toFormat('HH:mm a')}`,
        loadId: props.load.id,
        city,
        state,
        latitude,
        longitude,
        temperature,
      };

      await sendEDIUpdate(props.load.id, payload);
      handleToast(ToastType.Success, 'EDI Update Sent Successfully');
      reset();
    },
  });

  const fetchEdiStatusCodes = async () => {
    if (orderStore.order.customerId === null) return;
    try {
      const res = await fetchEDIStatusCodes(orderStore.order.customerId);
      setStatusCodes(res);
    } catch (error) {
      handleToast(ToastType.Error, 'Error fetching EDI status codes');
    }
  };

  const getEventMenuItems = () =>
    groupBy(
      [
        ...mapCodesToMenuItems(statusCodes().statusCodes),
        ...mapCodesToMenuItems(statusCodes().appointmentCodes).map((i) => ({
          ...i,
          category: 'Appointment',
        })),
      ],
      'category',
    );

  const showAppointment = () =>
    find(statusCodes().appointmentCodes, { code: data().shipmentStatus });

  const x6Selected = () => data().shipmentStatus.includes('X6');

  onMount(async () => {
    if (orderStore.order.edi) await fetchEdiStatusCodes();
  });

  return (
    <form ref={form}>
      <Card
        startTitle="Send EDI Update"
        endIcon={
          <Button
            disabled={orderStore.order.loadTenderId === null}
            type="submit"
            label="Send Update"
            sx={{ background: '#468DB5', color: '#FFF' }}
          />
        }
      >
        <Stack spacing={2}>
          <Notification
            type="info"
            text="This is the alternative to logging into your customer portal to log shipment updates. Communication is not instantaneous, there is a transit time."
          />
          <Show when={orderStore.order.loadTenderId !== null}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <SelectField
                    label="Stop"
                    menuItems={getStopLocation() as unknown as MenuItemType[]}
                    value={data().stopId}
                    onChange={(e) =>
                      setFields('stopId', Number(e.target.value))
                    }
                    placeholder="Select Stop"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectField
                    label="Event"
                    menuItems={getEventMenuItems()}
                    value={data().shipmentStatus}
                    groupBy={true}
                    onChange={(e) => {
                      if (!e.target.value.includes('X6')) {
                        setFields('city', null);
                        setFields('state', null);
                        setFields('latitude', null);
                        setFields('longitude', null);
                        setFields('temperature', null);
                      }
                      setFields('shipmentStatus', e.target.value);
                    }}
                    placeholder="Select Event"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectField
                    label="Reason"
                    menuItems={mapCodesToMenuItems(statusCodes().statusReasons)}
                    value={data().statusReason}
                    onChange={(e) => setFields('statusReason', e.target.value)}
                    placeholder="Select Reason"
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <DatePicker
                    label="When"
                    value={data().date.toISOString()}
                    handleChange={(val) => {
                      setFields('date', DateTime.fromISO(val).toJSDate());
                    }}
                    placeholder="Select Date"
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TimePicker
                    label="Time"
                    value={data().time}
                    onChange={(val) => setFields('time', val)}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <SelectField
                    label="Timezone"
                    menuItems={[
                      { label: 'Eastern', value: 'EST' },
                      { label: 'Central', value: 'CST' },
                      { label: 'Mountain', value: 'MST' },
                      { label: 'Pacific', value: 'PST' },
                    ]}
                    value={data().timezone}
                    onChange={(e) => setFields('timezone', e.target.value)}
                  />
                </Grid>
                <Show
                  when={showAppointment() !== undefined}
                  fallback={<Grid item xs={12} sm={4}></Grid>}
                >
                  <Grid item xs={12} sm={4}>
                    <TextInput
                      label="Appointment #"
                      name="appointmentStatus"
                      placeholder="Enter appointment #"
                    />
                  </Grid>
                </Show>
                <Show when={x6Selected()}>
                  <Grid item sm={3}>
                    <TextInput label="City" name="city" placeholder="City" />
                  </Grid>
                  <Grid item sm={3}>
                    <TextInput label="State" name="state" placeholder="State" />
                  </Grid>
                  <Grid item sm={2}>
                    <TextInput
                      label="Latitude"
                      name="latitude"
                      placeholder="Latitude"
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <TextInput
                      label="Longitude"
                      name="longitude"
                      placeholder="Longitude"
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <TextInput
                      label="Temperature"
                      name="temperature"
                      placeholder="Temperature"
                    />
                  </Grid>
                </Show>
                <Grid item xs={12}>
                  <TextInput
                    label="Note"
                    multiline
                    rows={2}
                    name="note"
                    placeholder="Enter notes here"
                  />
                </Grid>
              </Grid>
            </Box>
          </Show>
          <Show when={orderStore.order.loadTenderId === null}>
            <Notification
              type="warning"
              text="No Load Tender is attached. Cannot send EDI Updates without a Load Tender"
            />
          </Show>
        </Stack>
      </Card>
    </form>
  );
};

export default SendEDIUpdate;
