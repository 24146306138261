import { datadogRum } from '@datadog/browser-rum';

const extractLocationInfo = () => {
  const obj: Record<string, unknown> = {};

  for (const key in window.location) {
    obj[key] = window.location[key as unknown as keyof typeof window.location];
  }

  return obj;
};

/**
 * Send an error to Datadog.
 * @param errorType This should be the name of the function or another unique id.
 * @param error The error object itself.
 */
export const logError = (errorName: string, error: Error) => {
  const context = {
    name: errorName,
    env: extractLocationInfo(),
    dimensions: {
      height: window.innerHeight,
      width: window.innerWidth,
    },
    session: {
      ...datadogRum.getUser(),
      sessionReplayLink: datadogRum.getSessionReplayLink(),
    },
  };

  datadogRum.addError(error, context);
};
