import { BasicModal } from '@components';
import { TextInput } from '@components/forms';
import { Alert } from '@suid/material';
import { Component, createSignal } from 'solid-js';

type PropsT = {
  id: string;
  hasError?: boolean;
  onSave: (name: string) => void;
  onCloseModal: (label: string) => void;
};

export const SaveModal: Component<PropsT> = (props: PropsT) => {
  const [name, setName] = createSignal('');

  return (
    <BasicModal
      title="Name Your Filter"
      id={props.id}
      onSubmit={() => props.onSave(name())}
      onClose={() => props.onCloseModal(props.id)}
      showClose={false}
      width={'800'}
    >
      <div class="flex mb-3">
        {props.hasError !== undefined && props.hasError ? (
          <Alert severity="error">There was an error saving the filter</Alert>
        ) : undefined}
        <TextInput
          id="filterName"
          variant="outlined"
          label="Filter Name"
          placeholder="Filter Name"
          name="filterName"
          onChange={(val: string) => setName(val.slice(0, 80))}
          maxLength={80}
        />
      </div>
    </BasicModal>
  );
};
