import {
  EmptyData,
  Notification,
  TableRowErrors,
  ToastType,
  Typography,
} from '@components';
import { DialogBox } from '@components/DialogBox';
import { TextInput } from '@components/forms';
import { openDialogBox } from '@store/dialogBox';
import {
  LineItemViewModel,
  orderStore,
  updateVendorPropertyAtIndex,
  validateFieldForOrder,
  vendorEFSCheckRequest,
} from '@store/orders';
import { VendorContractViewModel, VendorLineItem } from '@store/orders/types';
import {
  AddCircleOutlineOutlined,
  Delete,
  MonetizationOnOutlined,
} from '@suid/icons-material';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@suid/material';
import {
  formatAmount,
  handleToast,
  isLTLAdmin,
  orderTableErrors,
  printLog,
} from '@utils/utils';
import { debounce, get, size } from 'lodash';
import { Accessor, Index, Show, createSignal } from 'solid-js';

import { removeTableRow } from '../stops/stopsEditor/utilFunctions';
import classes from './classes';

export type Props = {
  vendor: VendorContractViewModel;
  index: number;
};

export const VendorPay = (props: Props) => {
  const [deleteRow, setDeleteRow] = createSignal<number | null>(null);

  const getFilteredLineItems = () =>
    get(props.vendor, 'lineItems', []).filter(
      (item) => item.operationType !== 'Delete',
    );

  const addRow = () => {
    const lineItems = props.vendor.lineItems ? [...props.vendor.lineItems] : [];
    const insertObj: LineItemViewModel = {
      operationType: 'Insert',
      type: 'Accessorial',
      quantity: 1,
      description: '',
      rate: 0,
      id: 0,
      estimate: false,
      readOnly: false,
      synched: false,
      adjustment: false,
      stopId: 0,
    };
    lineItems.push(insertObj);
    updateVendorPropertyAtIndex({ lineItems: lineItems });
  };

  const headers = () => {
    const columnHeaders = [
      'Description',
      'Qty',
      'Rate',
      'EFS Request',
      'Total',
    ];
    if (isFalveyInsurance()) {
      columnHeaders.splice(3, 1);
    }
    return columnHeaders;
  };

  const cellStyle = {
    padding: '10px',
    borderBottom: '0px !important',
    minWidth: '140px',
    maxWidth: '140px',
  };

  const computeTotalLineItems = () => {
    return size(getFilteredLineItems()) > 0
      ? getFilteredLineItems().reduce(
          (acc, item) => acc + Number(item.rate) * Number(item.quantity),
          0,
        )
      : 0;
  };

  const onLineItemsChange = (
    key: string,
    value: string | number | boolean,
    index: number,
  ) => {
    let lineItems = props.vendor.lineItems ? [...props.vendor.lineItems] : [];
    lineItems = lineItems.filter((item) => item.operationType !== 'Delete');
    if (lineItems[index].operationType == 'Insert') {
      lineItems[index] = { ...lineItems[index], [key]: value };
    } else {
      lineItems[index] = {
        ...lineItems[index],
        [key]: value,
        operationType: 'Update',
      };
    }
    updateVendorPropertyAtIndex({ lineItems: lineItems });
  };

  const debouncedApiCall = debounce(async (itemId, description, rate) => {
    await vendorEFSCheckRequest({
      lineItemId: itemId,
      description: description,
      rate: rate,
    });
  }, 500);

  const getEfsRequestStyling = (lineItemId) => {
    const lineItem: LineItemViewModel = orderStore.order.vendors?.[
      orderStore.activeTab.index
    ]?.lineItems?.find((obj: LineItemViewModel) => obj.id === lineItemId);

    const statusClassMap = {
      'Check Approved': classes.successColor,
      Requested: classes.successColor,
      Unsettled: classes.dollarIcon,
      'Pending Sync': classes.dollarIcon,
    };

    let classString =
      (statusClassMap[lineItem.status] as string) || classes.dollarIcon;
    if (lineItem.readOnly) {
      classString += ' cursor-not-allowed';
    }

    return classString;
  };

  const userCanEFS = async (lineItemId: number, item: LineItemViewModel) => {
    const lineItem: LineItemViewModel = orderStore.order.vendors?.[
      orderStore.activeTab.index
    ].lineItems.find((obj: LineItemViewModel) => obj.id === lineItemId);

    switch (lineItem.status) {
      case 'Check Approved':
      case 'Requested':
        handleToast(
          ToastType.Caution,
          'Check has already been requested or approved.',
        );
        return false;
      case 'Unsettled':
        try {
          await debouncedApiCall(item.id, item.description, item.rate);
        } catch (error) {
          printLog('Failed to make EFS check request:', error);
        }
        break;
    }

    if (props.vendor.lineItems?.length > 0) {
      const updatedLineItems = props.vendor.lineItems.map((currentItem) =>
        currentItem.id === lineItemId
          ? { ...currentItem, status: 'Check Approved' }
          : currentItem,
      );
      updateVendorPropertyAtIndex({ lineItems: updatedLineItems });
    }

    return false;
  };

  const isFalveyInsurance = () => {
    return props.vendor.name === 'Falvey Shippers Insurance';
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Notification
          type={'info'}
          text={
            'To Request an EFS Check to pay a Vendor, please email carriers@armstrongtransport.com'
          }
        />
        <Table>
          <TableHead class="head">
            <TableRow>
              {headers().map((item) => (
                <TableCell>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <Show when={size(getFilteredLineItems()) === 0}>
            <TableBody class={classes.emptyTable}>
              <TableRow>
                <TableCell colspan={headers().length + 1} style={{ border: 0 }}>
                  <EmptyData />
                </TableCell>
              </TableRow>
            </TableBody>
          </Show>
          <Show when={size(getFilteredLineItems()) > 0}>
            <TableBody class={classes.tableBg}>
              <Index each={getFilteredLineItems()}>
                {(item: Accessor<VendorLineItem>, index) => (
                  <>
                    <TableRow class={classes.tableRow}>
                      <TableCell
                        sx={{
                          ...cellStyle,
                          maxWidth: 'auto',
                          minWidth: '35%',
                        }}
                      >
                        <TextInput
                          classes={'bg-white rounded-md'}
                          value={item().description}
                          onChange={(value: string) => {
                            onLineItemsChange('description', value, index);
                          }}
                          label=""
                          placeholder="Description"
                          disabled={isFalveyInsurance() && !isLTLAdmin()}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          ...cellStyle,
                          minWidth: '70px',
                          maxWidth: '70px',
                        }}
                      >
                        <TextInput
                          label=""
                          classes={'bg-white rounded-md'}
                          type="number"
                          value={item().quantity}
                          onChange={(value: string | number | null | Event) => {
                            onLineItemsChange(
                              'quantity',
                              value as unknown as number,
                              index,
                            );
                          }}
                          placeholder="Qty"
                          onBlur={() =>
                            validateFieldForOrder(
                              `vendors.${props.index}.lineItems.${index}.quantity`,
                              `vendors[${props.index}].lineItems[${index}].quantity`,
                            )
                          }
                          error={
                            orderStore.orderFormError &&
                            orderStore.orderFormError[
                              `vendors.${props.index}.lineItems.${index}.quantity`
                            ]
                          }
                          noErrorMessage
                          disabled={isFalveyInsurance() && !isLTLAdmin()}
                        />
                      </TableCell>
                      <TableCell sx={cellStyle}>
                        <TextInput
                          classes={'bg-white rounded-md'}
                          value={item().rate}
                          onChange={(value: string) => {
                            onLineItemsChange('rate', value, index);
                          }}
                          significantDigits={2}
                          label=""
                          type="number"
                          placeholder="Rate"
                          onBlur={() =>
                            validateFieldForOrder(
                              `vendors.${props.index}.lineItems.${index}.rate`,
                              `vendors[${props.index}].lineItems[${index}].rate`,
                            )
                          }
                          error={
                            orderStore.orderFormError &&
                            orderStore.orderFormError[
                              `vendors.${props.index}.lineItems.${index}.rate`
                            ]
                          }
                          noErrorMessage
                          disabled={isFalveyInsurance() && !isLTLAdmin()}
                        />
                      </TableCell>
                      <Show when={!isFalveyInsurance()}>
                        <TableCell sx={{ width: '120px', textAlign: 'center' }}>
                          <IconButton
                            onClick={() => userCanEFS(item().id, item())}
                            disabled={isFalveyInsurance()}
                          >
                            <MonetizationOnOutlined
                              class={getEfsRequestStyling(item().id)}
                            />
                          </IconButton>
                        </TableCell>
                      </Show>
                      <TableCell sx={cellStyle}>
                        <Typography
                          variant="body1"
                          class={`${classes.totalAmount} !font-normal`}
                        >
                          {formatAmount(
                            Number(item().quantity) * Number(item().rate),
                          )}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ ...cellStyle, minWidth: 'fit-content' }}>
                        <Show when={!isFalveyInsurance()}>
                          <IconButton
                            onClick={() => {
                              setDeleteRow(index);
                              openDialogBox('deleteVendorLineItem');
                            }}
                            disabled={isFalveyInsurance()}
                          >
                            <Delete class="text-[#B00020] cursor-pointer" />
                          </IconButton>
                        </Show>
                      </TableCell>
                    </TableRow>
                    <TableRowErrors
                      columnsLength={headers().length}
                      tableErrors={orderTableErrors(
                        ['rate', 'quantity', 'type'],
                        `vendors[${props.index}].lineItems[{index}].{field}`,
                        index,
                      )}
                    />
                  </>
                )}
              </Index>
            </TableBody>
          </Show>
          <TableFooter class={classes.tableFooter}>
            <TableRow class={classes.tableFooterRow}>
              <TableCell>
                <Show when={!isFalveyInsurance()}>
                  <IconButton onClick={addRow} disabled={isFalveyInsurance()}>
                    <AddCircleOutlineOutlined class={classes.addIcon} />
                  </IconButton>
                </Show>
              </TableCell>
              <TableCell colspan={isFalveyInsurance() ? 1 : 2}></TableCell>
              <TableCell>
                <Typography variant="body1" class={classes.totalLabel}>
                  Total
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" class={classes.totalAmount}>
                  {formatAmount(computeTotalLineItems())}
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <DialogBox
        id="deleteVendorLineItem"
        title={'Are you sure you want to delete this line item?'}
        onSubmit={() => {
          removeTableRow(deleteRow()!, props.vendor, 'vendor', 'lineItems');
          setDeleteRow(null);
        }}
        onSubmitText="Delete"
      />
    </>
  );
};
