import { BasicModal, BasicTable, Button, Typography } from '@components';
import { Column } from '@components/BasicTable/BasicTable';
import { DialogBox } from '@components/DialogBox';
import { openDialogBox } from '@store/dialogBox';
import { closeModal, openModal } from '@store/modals';
import { ICustomerPayment, ILoadEditViewModel } from '@store/orders';
import InfoIcon from '@suid/icons-material/Info';
import DeleteIcon from '@suid/icons-material/Delete';
import { Grid, IconButton } from '@suid/material';
import { formatAmount, isAdmin, isSuperAdmin } from '@utils/utils';
import { AddMissingEfsCheckModal } from '@views/order/components';
import { CARRIER_INSTRUCTION_MODAL_ID } from '@views/order/constants';
import { Show, createSignal } from 'solid-js';

import classes from './classes';
import { removeTableRow } from './stops/stopsEditor/utilFunctions';
import { CheckDetails } from './CheckDetails';

const iconStyles = {
  color: '#0070A2',
  cursor: 'pointer',
};
export const checkDetailsModalId = 'checkDetailsModal';
type Props = {
  load: ILoadEditViewModel;
  tabIndex: number;
};

export const CarrierPayments = (props: Props) => {
  const [deleteRow, setDeleteRow] = createSignal<number | null>(null);
  const [activeRow, setActiveRow] = createSignal<ICustomerPayment | null>(null);

  const modalId = `${CARRIER_INSTRUCTION_MODAL_ID}-${props.tabIndex}`;

  const columns: Column<ICustomerPayment>[] = [
    {
      name: 'Date',
      key: 'date',
      renderCell: (rowData: ICustomerPayment) => rowData.paidDate as string,
    },
    {
      name: 'Description',
      key: 'description',
      renderCell: (rowData: ICustomerPayment) => rowData.description,
    },
    // @ts-expect-error - type sauce
    isAdmin() && {
      name: 'Reference',
      key: 'reference',
      renderCell: (rowData: ICustomerPayment) => rowData.referenceNum,
    },
    {
      name: 'Amount',
      key: 'amount',
      renderCell: (rowData: ICustomerPayment) =>
        formatAmount(rowData.amount ?? 0),
    },
    {
      name: 'Payment #',
      key: 'payment',
      renderCell: (rowData: ICustomerPayment) => rowData.checkNumber,
    },
    {
      name: 'Info',
      key: 'info',
      minWidth: 100,
      renderCell: (rowData: ICustomerPayment, index: number) => (
        <Grid container gap={1} alignItems="center">
          <Show
            when={rowData.checkNumber}
            fallback={<div class="w-[40px]"></div>}
          >
            <IconButton>
              <InfoIcon
                sx={{ ...iconStyles, fontSize: '1.5rem' }}
                onClick={() => {
                  setActiveRow(rowData);
                  openModal(checkDetailsModalId);
                }}
              />
            </IconButton>
          </Show>
          <IconButton
            disabled={!isSuperAdmin()}
            onClick={() => {
              setDeleteRow(index);
              openDialogBox('deleteCarrierPaymentRow');
            }}
            class="!ml-1"
          >
            <DeleteIcon
              sx={{
                ...iconStyles,
                fontSize: '1.5rem',
                color: '#B00020',
              }}
            />
          </IconButton>
        </Grid>
      ),
    },
  ];

  const footerRow = [
    {
      key: 'ideaTitle',
    },
    {
      key: '#',
    },
    {
      renderCell: () => (
        <Typography variant="body1" class={classes.totalLabel}>
          Outstanding
        </Typography>
      ),
      key: 'Outstanding',
    },
    {
      renderCell: () => (
        <Typography variant="body1" class={classes.totalAmount}>
          {formatAmount(
            (typeof props.load.lineItems !== 'undefined'
              ? props.load.lineItems
                  .filter((v) => v.operationType !== 'Delete')
                  .reduce(
                    (acc, item) =>
                      acc + Number(item.rate) * Number(item.quantity),
                    0,
                  )
              : 0) -
              (typeof props.load.payments !== 'undefined'
                ? props.load.payments
                    .filter((v) => v.operationType !== 'Delete')
                    .reduce((acc, item) => acc + Number(item.amount), 0)
                : 0),
          )}
        </Typography>
      ),
      key: '#',
    },
    {
      renderCell: () => (
        <Typography variant="body1" class={classes.totalLabel}>
          Total Paid
        </Typography>
      ),
      key: '#',
    },
    {
      key: '#',
      renderCell: () => (
        <Typography variant="body1" class={classes.totalAmount}>
          {typeof props.load.payments !== 'undefined'
            ? formatAmount(
                props.load.payments
                  .filter((v) => v.operationType !== 'Delete')
                  .reduce((acc, item) => acc + Number(item.amount), 0),
              )
            : 0}
        </Typography>
      ),
    },
  ];

  return (
    <>
      <div class="flex justify-end">
        <Show when={isAdmin()}>
          <Button
            label="Add EFS Payment"
            color="primary"
            onClick={() => openModal(modalId)}
            sx={{ color: '#FFF' }}
          />
        </Show>
      </div>
      <BasicTable<ICustomerPayment>
        columns={columns}
        rows={
          props.load.payments?.filter((x) => x.operationType !== 'Delete') ?? []
        }
        footerRow={footerRow}
        tableBodyClasses={classes.tableBg}
        tableFooterClasses={classes.tableFooter}
        tableBodyRowClasses={classes.tableRow}
        tableFooterRowClasses={classes.tableFooterRow}
      />
      <DialogBox
        id="deleteCarrierPaymentRow"
        title={'Are you sure you want to delete this payment?'}
        onSubmit={() => {
          removeTableRow(deleteRow()!, props.load, 'load', 'payments');
          setDeleteRow(null);
        }}
        onSubmitText="Delete"
      />
      <BasicModal
        title="Check Details"
        id={checkDetailsModalId}
        onClose={() => closeModal(checkDetailsModalId)}
        showClose={false}
        header={false}
        width={'40vw'}
        footer={false}
      >
        <CheckDetails activeRow={activeRow()!} />
      </BasicModal>
      <BasicModal
        title="Add Missing EFS Check"
        id={modalId}
        onClose={() => closeModal(modalId)}
        showClose={false}
        width={'70vw'}
        footer={false}
      >
        <AddMissingEfsCheckModal
          load={props.load}
          tabIndex={props.tabIndex}
          modalId={modalId}
        />
      </BasicModal>
    </>
  );
};
